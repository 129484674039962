import React, { useState, useEffect } from "react";
import "./sessionContainer.css";
import { IoMdPause } from "react-icons/io";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import EasySpeech from "easy-speech";
import { motion, AnimatePresence } from "framer-motion";
import scenario from "./data/scenario";

const AssesmentPanel = ({ children, open, onClose, record }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [btnHide, setBtnHide] = useState(false);

  const textToSpeech = async (action) => {
    try {
      // Extract relevant data and convert it to a readable string
      const patientDetails = scenario[activeTab].scenario.patientDetails;
      const presentation = scenario[activeTab].scenario.presentation;
      const management = scenario[activeTab].scenario.management;

      const textToRead = `
      Scenario Title:${patientDetails.title}.
        Patient Name: ${patientDetails.name}.
        Age: ${patientDetails.age}.
        Gender: ${patientDetails.gender}.
        Weight: ${patientDetails.weight}.
        Known Allergies: ${patientDetails.medicalHistory.knownAllergies}.
        Chronic Conditions: ${patientDetails.medicalHistory.chronicConditions}.
        Chief Complaint: ${presentation.chiefComplaint}.
        Vital Signs: Heart Rate ${presentation.vitalSigns.heartRate}, 
        Respiratory Rate ${presentation.vitalSigns.respiratoryRate}, 
        Oxygen Saturation ${presentation.vitalSigns.oxygenSaturation}, 
        Blood Pressure ${presentation.vitalSigns.bloodPressure}.
        Symptoms: ${presentation.symptoms.join(", ")}.
        Initial Actions: ${management.initialActions.join(", ")}.
        Nebulized Medication: ${
          management.interventions.nebulizedMedications.medication
        },
        Dose: ${management.interventions.nebulizedMedications.dose}.
        Intubation Indication: ${
          management.interventions.intubation.indication
        }.
        ET Tube Size: ${management.interventions.intubation.ETTubeSize}.
      `;

      setBtnHide(!btnHide);

      await EasySpeech.init();

      const voices = EasySpeech.voices();
      const voice = voices[2]; // You can adjust the index based on your preference

      const utterance = new SpeechSynthesisUtterance(textToRead.trim());
      utterance.voice = voice;
      utterance.onerror = (event) => {
        console.error("Speech synthesis error:", event);
      };

      utterance.onend = () => {
        console.log("Speech synthesis complete.");
        setBtnHide(false);
      };

      if (action === "play") {
        await EasySpeech.speak(utterance);
      } else {
        EasySpeech.cancel();
      }
    } catch (error) {
      console.error("An error occurred during speech synthesis:", error);
    }
  };
  const popupVariants = {
    hidden: { opacity: 0, scale: 0.8, y: -50 },
    visible: { opacity: 0.9, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.8, y: 50 },
  };
  const tabs = [
    "Scenario 1",
    "Scenario 2",
    "Scenario 3",
    "Scenario 4",
    "Scenario 5",
    "Scenario 6",
    "Scenario 7",
    "Scenario 8",
    "Scenario 9",
    "Scenario 10",
  ];

  if (!open) return null;
  return (
    <div className="search-session-modal">
      <motion.div
        className="relative w-[80%] h-[90%] bg-backgroundModal text-opacity-100 opacity-95  p-4 "
        variants={popupVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {/* Tabs */}
        <div className="w-full flex   tabs-scrollbar overflow-x-auto pt-2">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`flex justify-start hover:text-yellow-200 text-center  min-w-fit text-xl px-2 font-semibold  ${
                activeTab === index
                  ? "border-b-2 border-blue-500 text-yellow-400"
                  : "text-textColor"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="w-full p-2">
          {!btnHide ? (
            <button
              className="bg-red-600 hover:bg-red-700 text-center flex items-center px-3 py-1 text-white cursor-pointer"
              onClick={() => textToSpeech("play")}
            >
              <HiMiniSpeakerWave size={30} className=" " />
              Listen
            </button>
          ) : (
            <button
              className="bg-red-600 hover:bg-red-700 text-center flex items-center px-3 py-1 text-white cursor-pointer "
              onClick={() => textToSpeech("stop")}
            >
              <IoMdPause size={30} className="" />
              Pause
            </button>
          )}
        </div>

        {/* Tab Content */}

        <div className="w-full text-textColor overflow-y-auto tabdetails-scrollbar text-opacity-100 text-center p-2 h-[78%]">
          <div className="text-justify w-full">
            {" "}
            <p className="text-xl">
              Title: {scenario[activeTab].scenario.patientDetails.title}
            </p>
          </div>
          <div className="w-full flex justify-between gap-x-2">
            <p className="text-xl">
              Patient Name:{" "}
              <span className="font-bold text-yellow-200">
                {scenario[activeTab].scenario.patientDetails.name}
              </span>
            </p>
            <p className="text-xl">
              Patient Age:{" "}
              <span className="font-bold text-yellow-200">
                {scenario[activeTab].scenario.patientDetails.age}
              </span>
            </p>
            <p className="text-xl">
              Patient Gender:{" "}
              <span className="font-bold text-yellow-200">
                {scenario[activeTab].scenario.patientDetails.gender}
              </span>
            </p>
            <p className="text-xl">
              Patient Weight:{" "}
              <span className="font-bold text-yellow-200">
                {scenario[activeTab].scenario.patientDetails.weight}
              </span>
            </p>
          </div>
          <div className="w-full flex justify-between">
            <p className="text-xl">
              Known Allergies:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.patientDetails.medicalHistory
                    .knownAllergies
                }
              </span>
            </p>
            <p className="text-xl">
              Chronic Conditions:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.patientDetails.medicalHistory
                    .chronicConditions
                }
              </span>
            </p>
          </div>
          <div className="flex w-full flex-col text-justify">
            <h5 className="">Complaint :</h5>
            <p className="text-lg">
              {scenario[activeTab].scenario.presentation.chiefComplaint}
            </p>
          </div>
          <div className="w-full text-xl flex gap-5 justify-start">
            <p>
              Heart Rate:{" "}
              <span className="font-bold text-yellow-200">
                {scenario[activeTab].scenario.presentation.vitalSigns.heartRate}
              </span>
            </p>
            <p>
              Respiratory Rate:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.presentation.vitalSigns
                    .respiratoryRate
                }
              </span>
            </p>
          </div>
          <div className="w-full text-xl flex gap-5 justify-start">
            <p>
              Oxygen Saturation:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.presentation.vitalSigns
                    .oxygenSaturation
                }
              </span>
            </p>
            <p>
              Blood Pressure:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.presentation.vitalSigns
                    .bloodPressure
                }
              </span>
            </p>
          </div>
          <div className="w-full">
            <p className="text-justify text-lg">
              Symptomps:{" "}
              <span className="font-bold text-yellow-200">
                {scenario[activeTab].scenario.presentation.symptoms.join(", ")}{" "}
              </span>
            </p>
          </div>
          <div className="e-full">
            <p className="text-justify text-xl">
              Initial Actions:{" "}
              <span className="font-bold text-yellow-200">
                {scenario[activeTab].scenario.management.initialActions.join(
                  ", "
                )}{" "}
              </span>
            </p>
          </div>
          <div className="w-full flex justify-start gap-5 text-lg">
            <p>
              Nebulized Medication:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.management.interventions
                    .nebulizedMedications.medication
                }
              </span>
            </p>

            <p>
              Dose:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.management.interventions
                    .nebulizedMedications.dose
                }
              </span>
            </p>
          </div>
          <div className="w-full">
            <p className="text-xl text-justify">
              Indication:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.management.interventions
                    .intubation.indication
                }
              </span>
            </p>
          </div>
          <div className="w-full">
            <p className="text-xl text-justify">
              ET Tube Size:{" "}
              <span className="font-bold text-yellow-200">
                {
                  scenario[activeTab].scenario.management.interventions
                    .intubation.ETTubeSize
                }
              </span>
            </p>
          </div>
        </div>
        <div className="w-full flex justify-end gap-x-1">
          {" "}
          <button
            size={30}
            className="bg-gray-600 bg-opacity-100 text-opacity-100 px-4  py-2 text-center text-white hover:bg-gray-700 cursor-pointer"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className={`px-4 py-2 text-center text-opacity-100 ${
              scenario[activeTab].scenario.patientDetails.active
                ? "bg-red-600 text-white hover:bg-red-700 cursor-pointer"
                : "bg-red-400 text-gray-200 cursor-not-allowed"
            }`}
            disabled={!scenario[activeTab].scenario.patientDetails.active}
            onClick={
              scenario[activeTab].scenario.patientDetails.active ? record : null
            }
          >
            Start Recording
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default AssesmentPanel;
