const scenario = [
  {
    scenario: {
      patientDetails: {
        title: "Traumatic Airway Injury in the ER for 45-Year-Old Man",
        name: "Michael Brown",
        age: 45,
        active: true,
        gender: "Male",
        weight: "80 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Hypertension",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 45-year-old male, arrived at the ER after a motor vehicle collision. He presented with significant swelling and deformity of the neck and difficulty breathing. Examination revealed subcutaneous emphysema, hoarseness, and stridor, indicating potential airway compromise.",
        vitalSigns: {
          heartRate: "110 bpm",
          respiratoryRate: "30 breaths/min",
          oxygenSaturation: "88%",
          bloodPressure: "140/90 mmHg",
        },
        symptoms: ["Subcutaneous emphysema", "Hoarseness", "Stridor"],
      },
      management: {
        initialActions: ["Administered high-flow oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Traumatic airway injury",
            ETTubeSize: "7.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Allergic Reaction in the ER for 40-Year-Old Man",
        name: "Robert Miller",
        age: 40,
        active: true,
        gender: "Male",
        weight: "85 kg",
        medicalHistory: {
          knownAllergies: "Bee stings",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 40-year-old male, presented with swelling of the face and throat, difficulty breathing, and urticaria following a bee sting. Examination revealed stridor and signs of anaphylactic shock, including hypotension and cyanosis.",
        vitalSigns: {
          heartRate: "150 bpm",
          respiratoryRate: "40 breaths/min",
          oxygenSaturation: "80%",
          bloodPressure: "75/50 mmHg",
        },
        symptoms: ["Facial swelling", "Stridor", "Urticaria"],
      },
      management: {
        initialActions: ["Administered intramuscular epinephrine (0.5 mg)"],
        interventions: {
          nebulizedMedications: { medication: "Salbutamol", dose: "2.5 mg" },
          intubation: {
            indication: "Airway compromise due to anaphylaxis",
            ETTubeSize: "7.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Smoke Inhalation Injury in the ER for 38-Year-Old Man",
        name: "Chris Taylor",
        age: 38,
        active: true,
        gender: "Male",
        weight: "78 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 38-year-old male, presented to the ER after being rescued from a house fire. He had hoarseness, stridor, and soot in the oropharynx. Examination revealed severe respiratory distress and signs of thermal injury to the airway.",
        vitalSigns: {
          heartRate: "125 bpm",
          respiratoryRate: "35 breaths/min",
          oxygenSaturation: "84%",
          bloodPressure: "100/70 mmHg",
        },
        symptoms: ["Hoarseness", "Stridor", "Soot in oropharynx"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway swelling from thermal injury",
            ETTubeSize: "8.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Anaphylaxis in the ER for 32-Year-Old Woman",
        name: "Emily Johnson",
        age: 32,
        active: false,
        gender: "Female",
        weight: "60 kg",
        medicalHistory: {
          knownAllergies: "Peanuts",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 32-year-old female, arrived at the ER with swelling of the face, lips, and throat, following accidental ingestion of peanuts. She exhibited severe respiratory distress with audible stridor and widespread urticaria. Additional signs included cyanosis and hypotension.",
        vitalSigns: {
          heartRate: "145 bpm",
          respiratoryRate: "40 breaths/min",
          oxygenSaturation: "82%",
          bloodPressure: "75/50 mmHg",
        },
        symptoms: ["Facial swelling", "Stridor", "Cyanosis"],
      },
      management: {
        initialActions: ["Administered intramuscular epinephrine (0.3 mg)"],
        interventions: {
          nebulizedMedications: { medication: "Salbutamol", dose: "2.5 mg" },
          intubation: {
            indication: "Severe airway swelling",
            ETTubeSize: "6.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Post-Surgical Airway Edema in the ER for 28-Year-Old Woman",
        name: "Sophia Carter",
        age: 28,
        active: false,
        gender: "Female",
        weight: "65 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 28-year-old female, presented to the ER with progressive difficulty breathing following neck surgery performed earlier in the day. Examination revealed hoarseness, stridor, and swelling of the neck. Her oxygen saturation was declining despite supplemental oxygen.",
        vitalSigns: {
          heartRate: "120 bpm",
          respiratoryRate: "28 breaths/min",
          oxygenSaturation: "88%",
          bloodPressure: "110/65 mmHg",
        },
        symptoms: ["Hoarseness", "Stridor", "Neck swelling"],
      },
      management: {
        initialActions: ["Administered high-flow oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway compromise from post-surgical edema",
            ETTubeSize: "6.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Foreign Body Aspiration in the ER for 5-Year-Old Girl",
        name: "Lily Martinez",
        age: 5,
        active: false,
        gender: "Female",
        weight: "20 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 5-year-old girl, was brought to the ER after sudden onset choking while eating peanuts. Examination revealed severe respiratory distress with inspiratory stridor, cyanosis, and diminished breath sounds on the right side. The child appeared anxious and was clutching her throat.",
        vitalSigns: {
          heartRate: "150 bpm",
          respiratoryRate: "42 breaths/min",
          oxygenSaturation: "78%",
          bloodPressure: "95/60 mmHg",
        },
        symptoms: [
          "Inspiratory stridor",
          "Cyanosis",
          "Diminished breath sounds",
        ],
      },
      management: {
        initialActions: ["Performed back blows and abdominal thrusts"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Complete airway obstruction",
            ETTubeSize: "4.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Croup Management in the ER for 3-Year-Old Boy",
        name: "James Smith",
        age: 3,
        active: false,
        gender: "Male",
        weight: "14 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 3-year-old boy, presented with a barking cough and difficulty breathing. Examination revealed inspiratory stridor and intercostal retractions, consistent with a diagnosis of croup. His symptoms worsened despite nebulized treatments at home.",
        vitalSigns: {
          heartRate: "140 bpm",
          respiratoryRate: "38 breaths/min",
          oxygenSaturation: "90%",
          bloodPressure: "90/55 mmHg",
        },
        symptoms: [
          "Barking cough",
          "Inspiratory stridor",
          "Intercostal retractions",
        ],
      },
      management: {
        initialActions: ["Administered nebulized epinephrine"],
        interventions: {
          nebulizedMedications: {
            medication: "Racemic Epinephrine",
            dose: "0.5 mL",
          },
          intubation: {
            indication: "Severe respiratory distress",
            ETTubeSize: "4.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Airway Compromise from Infection for 40-Year-Old Woman",
        name: "Laura Davis",
        age: 40,
        active: false,
        gender: "Female",
        weight: "68 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Type 2 Diabetes",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 40-year-old female, presented with a sore throat, fever, and difficulty breathing. Examination revealed swelling of the oropharynx and inspiratory stridor, indicating possible epiglottitis. The patient appeared anxious and fatigued.",
        vitalSigns: {
          heartRate: "125 bpm",
          respiratoryRate: "30 breaths/min",
          oxygenSaturation: "87%",
          bloodPressure: "115/70 mmHg",
        },
        symptoms: ["Sore throat", "Fever", "Inspiratory stridor"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway obstruction due to infection",
            ETTubeSize: "7.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Asthma Attack in the ER for 7-Year-Old Girl",
        name: "Olivia Thompson",
        age: 7,
        active: false,
        gender: "Female",
        weight: "25 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Asthma",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 7-year-old girl, was brought to the ER with severe respiratory distress following exposure to pet dander. Examination revealed audible wheezing, use of accessory muscles, and cyanosis.",
        vitalSigns: {
          heartRate: "140 bpm",
          respiratoryRate: "40 breaths/min",
          oxygenSaturation: "84%",
          bloodPressure: "95/65 mmHg",
        },
        symptoms: ["Wheezing", "Accessory muscle use", "Cyanosis"],
      },
      management: {
        initialActions: ["Administered 100% oxygen via non-rebreather mask"],
        interventions: {
          nebulizedMedications: { medication: "Albuterol", dose: "2.5 mg" },
          intubation: {
            indication: "Impending respiratory failure",
            ETTubeSize: "5.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Burn Airway Injury in the ER for 8-Year-Old Boy",
        name: "Ethan Wilson",
        age: 8,
        active: false,
        gender: "Male",
        weight: "30 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, an 8-year-old boy, presented to the ER after a house fire with signs of airway burns, including hoarseness, stridor, and facial burns. He was in severe respiratory distress and had soot in the oropharynx.",
        vitalSigns: {
          heartRate: "130 bpm",
          respiratoryRate: "36 breaths/min",
          oxygenSaturation: "86%",
          bloodPressure: "100/60 mmHg",
        },
        symptoms: ["Hoarseness", "Stridor", "Facial burns"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway swelling from thermal injury",
            ETTubeSize: "5.5 mm (cuffed)",
          },
        },
      },
    },
  },
];

export default scenario;
